import { useSubscriptionsContext } from 'SubscriptionsContext';
import { HighlightActiveMenuitem } from 'components/navigation/Components/LeftNav/initLeftNav';
import { useMyColumbusSubscriptions } from 'hooks/useMyColumbusSubscriptions';
import { ColumbusSubscriptionType } from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsTypes';
import useMyNetcrackerSubscriptions from 'hooks/useMyNetCrackerSubscriptions';
import React, { FC, useEffect } from 'react';
import {
  hasDualStackProducts,
  hasOnlyColumbusProducts,
  hasOnlyNetCrackerProducts,
} from 'utils/misc';
import { LeftNavSubscriptionsError } from './LeftNavSubscriptionsError';
import { LeftNavSubscriptionsLoader } from './LeftNavSubscriptionsLoader';
import { SubscriptionsNavItems } from './SubscriptionsNavItems';

export const LeftNavSubscriptions: FC = () => {
  const { migratedSubs } = useSubscriptionsContext();
  const isStackDual = hasDualStackProducts();
  const { netCrackerSubscriptions, netCrackerProductListErrorMessage, isLoadingNetCracker } =
    useMyNetcrackerSubscriptions();

  const { columbusSubscriptions, columbusSubscriptionsError, loadingColumbusSubscriptions } =
    useMyColumbusSubscriptions();

  const filteredColumbusSubs = columbusSubscriptions?.reduce(
    (acc: ColumbusSubscriptionType[], sub) => {
      const match = migratedSubs?.find(
        (elem) => elem.backwardsCompatibleIdentifier?.identifier === sub.subscriptionId,
      );
      if (!match) {
        return [...acc, sub];
      } else {
        return acc;
      }
    },
    [],
  );

  useEffect(() => {
    HighlightActiveMenuitem();
  }, [columbusSubscriptions]);

  return (
    <div>
      {(loadingColumbusSubscriptions || (isStackDual && isLoadingNetCracker)) && (
        <LeftNavSubscriptionsLoader />
      )}
      {hasDualStackProducts() && (
        <>
          {!loadingColumbusSubscriptions && !isLoadingNetCracker && (
            <div>
              <SubscriptionsNavItems
                subscriptions={[...filteredColumbusSubs, ...netCrackerSubscriptions]}
              />
            </div>
          )}
          {(netCrackerProductListErrorMessage || columbusSubscriptionsError) && (
            <LeftNavSubscriptionsError />
          )}
        </>
      )}
      {hasOnlyColumbusProducts() && (
        <>
          {!loadingColumbusSubscriptions && !columbusSubscriptionsError && (
            <div>
              <SubscriptionsNavItems subscriptions={filteredColumbusSubs} />
            </div>
          )}
          {columbusSubscriptionsError && <LeftNavSubscriptionsError />}
        </>
      )}
      {hasOnlyNetCrackerProducts() && (
        <>
          {!isLoadingNetCracker && !netCrackerProductListErrorMessage && (
            <div>
              <SubscriptionsNavItems subscriptions={netCrackerSubscriptions} />
            </div>
          )}
          {netCrackerProductListErrorMessage && <LeftNavSubscriptionsError />}
        </>
      )}
    </div>
  );
};
