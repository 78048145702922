import { useMySubscriptions } from 'api/customers';
import { AxiosError } from 'axios';
import { CustomerSubscription } from 'interfaces/ApiTypeAliases';
import React, { createContext, useContext, useEffect } from 'react';

type SubscriptionsContext = {
  subscriptions: CustomerSubscription[] | undefined;
  error: AxiosError<any, any> | null;
  isLoading: boolean;
  isFrozen: boolean | undefined;
  migratedSubs: CustomerSubscription[] | undefined;
};

type ProviderProps = {
  children: React.ReactNode;
  isLoggedIn: boolean;
};

const SubscriptionsContext = createContext<SubscriptionsContext | undefined>(undefined);

export const SubscriptionsProvider = ({ children, isLoggedIn }: ProviderProps) => {
  const tenMinutes = 10 * 60 * 1000;
  const {
    data: subscriptions,
    error,
    isLoading,
    refetch,
  } = useMySubscriptions(
    {
      // such that we get Odin identifier needed for endpoint
      EnableBackwardsCompatibility: true,
      // return PartialProduct (can't get FullProduct from this endpoint)
      DataLimitationPolicy: 'Partial',
      // don't return packages but rather the package products
      EnablePackageExpansion: true,
      // Include products being migrated to Netcracker
      IncludeMigratedData: true,
    },
    null,
    {
      suspense: true,
      staleTime: tenMinutes,
      enabled: false,
    },
  );
  const currentSubs = subscriptions?.filter((sub) => !sub.isMigrated);
  const migratedSubs = subscriptions?.filter((sub) => sub.isMigrated);
  const shouldFrozen = () => {
    if (migratedSubs && migratedSubs.length > 0) {
      return false;
    }
    return subscriptions?.some((sub) => sub.isFrozen);
  };

  const isFrozen = shouldFrozen();

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <SubscriptionsContext.Provider
      value={{ subscriptions: currentSubs, error, isLoading, isFrozen, migratedSubs }}>
      {children}
    </SubscriptionsContext.Provider>
  );
};

export const useSubscriptionsContext = () => {
  const context = useContext(SubscriptionsContext);
  if (context === undefined) {
    throw new Error(`useSubscriptionsContext must be used within a SubscriptionsContext`);
  }
  return context;
};
