import * as CFRichTextTypes from '@contentful/rich-text-types';
import { createClient, EntryFields } from 'contentful';

const client = createClient({
  space: import.meta.env.PUBLIC_CF_SPACE_ID as string,
  environment: import.meta.env.PUBLIC_CF_ENVIRONMENT as string,
  accessToken: import.meta.env.PUBLIC_CF_DELIVERY_ACCESS_TOKEN as string,
});

const previewClient = createClient({
  space: import.meta.env.PUBLIC_CF_SPACE_ID as string,
  environment: import.meta.env.PUBLIC_CF_ENVIRONMENT as string,
  accessToken: import.meta.env.PUBLIC_CF_PREVIEW_ACCESS_TOKEN as string,
  host: 'preview.contentful.com',
});

const netcrackerClient = createClient({
  space: import.meta.env.PUBLIC_NC_CF_SPACE_ID as string,
  environment: 'master',
  accessToken: import.meta.env.PUBLIC_NC_CF_DELIVERY_ACCESS_TOKEN as string,
  removeUnresolved: true,
});

const getClient = (preview: boolean) => (preview ? previewClient : client);

export async function getCognigyChatStyling() {
  const query = {
    limit: 1,
    include: 2,
    content_type: 'globalConfiguration',
  } as const;

  const {
    items: [globalConfig],
  } = await netcrackerClient.getEntries<any>(query);
  const CognigyStyleCss = globalConfig.fields.chatCss.fields.file.url;
  return CognigyStyleCss;
}

export async function getAlerts({ preview }: { preview?: boolean }) {
  const client = getClient(!!preview);
  const mys: TypeAlertsFields['domain'] = 'Mit YouSee';

  const { items } = await client.getEntries<TypeAlertsFields>({
    'content_type': 'alerts',
    'fields.domain': mys,
  });

  return items || [];
}

export const getContentfulAsset = async (name: string, preview = false) => {
  return await getClient(preview).getAssets({ 'fields.title': name });
};

export interface TypeAlertsFields {
  internalName?: EntryFields.Symbol;
  title: EntryFields.Symbol;
  body?: CFRichTextTypes.Block | CFRichTextTypes.Inline;
  type?: 'Error (red)' | 'Warning (yellow)' | 'Info (blue)';
  domain: 'Mit YouSee' | 'YouSee Help';
  slug: EntryFields.Symbol;
  distinctMatchSlug?: EntryFields.Boolean;
  start: EntryFields.Date;
  end?: EntryFields.Date;
  showInMYSApp?: EntryFields.Boolean;
}

const ENABLED = '1';

export const isPreviewEnabled = (query: URLSearchParams) => {
  const param = String(query?.get('preview')).toLowerCase();
  return param === ENABLED;
};
